import Head from 'next/head';
import _ from "lodash";
import Image from 'next/image';
import Script from "next/script";
import {getIndividualPageData, getPageInfo} from "../api/global";
import Typography from "@material-ui/core/Typography";
import GutenbergBody from '../components/01_atoms/GutenbergBody';
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CustomMuiButton from '../components/01_atoms/CustomMuiButton';

// Define structured data
const businessStructuredData = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Eaton Realty",
  "image": "https://www.eatonrealty.com/assets/eaton-reverse.png",
  "@id": "",
  "url": "https://www.eatonrealty.com/",
  "telephone": "+18136728022",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "14012 Spector Rd",
    "addressLocality": "Lithia",
    "addressRegion": "FL",
    "postalCode": "33547",
    "addressCountry": "US"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 27.85286230033583,
    "longitude": -82.25500155638565
  },
  "sameAs": [
    "https://www.facebook.com/EatonRealty/",
    "https://twitter.com/eatonrealty",
    "https://www.youtube.com/channel/UCW1wYBRwBM7TEp-v7TlxOJA",
    "https://www.linkedin.com/company-beta/3251634/"
  ]
};

export async function getStaticProps(context) {
  let initialProps = {
    // page: null,
    // page_data: {},
    // metatags: {},
    statusCode: 200,
  };

  let data = null;
  // Homepage NID in Drupal:
  const nid = 17234;

  try {
    if (nid && !isNaN(nid)) {
      // Listing data about currently opened listing.
      data = await getPageInfo(`/homepage`);
      if (data && data.page?.jsonapi) {
        const node_data = await getIndividualPageData(data.page.jsonapi.individual);
        if (node_data) {
          initialProps = {...initialProps, ...node_data};
        }
      }

      // Merge initially defined props with response from the backend.
      initialProps = {...initialProps, ...data};
    }

    if (!data) {
      return {
        notFound: true,
      }
    }
  }
  catch (e) {
    console.warn('HOMEPAGE retrieval error:', e);
    // Pass status code as internal properly. It is being checked inside of
    // render() method of _app.js.
    initialProps.statusCode = 500;

    // In case of Server Side Rendering, we want the server to throw the
    // correct error code.
    // if (res) {
    //   res.statusCode = 500;
    // }
  }

  return {
    props: {...initialProps /*, saleData: saleData, rentData: rentData*/}, // will
                                                                           // be
                                                                           // passed
                                                                           // to
                                                                           // the
                                                                           // page
                                                                           // component
                                                                           // as
                                                                           // props
    revalidate: 900,
    // notFound: true, // https://github.com/vercel/next.js/discussions/11862,
    // https://stackoverflow.com/questions/67946219/throw-new-errorfailed-to-load-static-props-when-setting-fallback-true-i/67946220#67946220
  }
}

export default function Home(props) {
  let node_data, body, body_json;
  if (props) {
    node_data = props?.page_data;
    ({body, body_json} = node_data);
  }

  return (
    <div>
      <Script type="application/ld+json" key={`schema-json`} id={`schema-json`}
              dangerouslySetInnerHTML={{ __html: JSON.stringify(businessStructuredData, null, "\t")}}>
      </Script>
      <Head>
        <title>Eaton Realty | Full-Service Real Estate and Property
          Management</title>
        <meta name="description"
              content="Eaton Realty is a Full-Service Real Estate Brokerage serving FishHawk, Valrico, Riverview, Brandon, and Apollo Beach -- all of Southeastern Hillsborough County. Expert guidance for Selling, Buying, and Managing Real Estate."/>
        <link rel="icon" href="/favicon.ico"/>
      </Head>
      <main>
        {!node_data &&
        <div>
          <h1>Welcome to Eaton Realty!</h1>
          <p>As our client, you receive the benefit of a full team of real
            estate experts.
            We have full-time staff, agents, and property managers available to
            assist you in any
            type of real estate transaction and we’re ready to help you achieve
            your real estate goals.</p>
        </div>
        }
        {(body_json?.json?.length > 0) &&
        <div>
          <Box pt={5} pb={10} bgcolor="grey.700" align="center"
               className="non-gb alignfull homepageHero">
                
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{color: '#FED36B', lineHeight: 1.5,}}
                              variant="h1">REAL
                    ESTATE <strong>SIMPLIFIED</strong></Typography>
                  <Typography style={{color: '#FFFFFF', paddingBottom: 30}}>MOVE
                    CONFIDENTLY WITH LESS STRESS</Typography>
                  <CustomMuiButton color="primary" size="medium"
                                   label="Start My" stronglabel='Journey'
                                   href="#journey"></CustomMuiButton>

                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box mb={5} bgcolor="grey.300" p={5} align="center"
               className="alignfull">
            <Container>
              <Grid container alignItems="center">
                <Grid item xs={12} md={6}>
                  <Typography variant="h2">THE
                    PERFECT <br/><strong>STORM</strong></Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>Real Estate transactions are filled with wrong
                    turns, pitfalls, and roadblocks. It’s a complicated,
                    time-consuming world, where if you don’t know your way, you
                    can quickly become lost. What happens when your inexperience
                    faces a detailed contract full of confusing jargon? Or you
                    stumble upon an inexperienced, part-time agent who isn’t
                    accessible and offers you little guidance?</Typography>
                  <Typography>When a deal goes wrong, you are the one that
                    suffers and must face the emotional, financial, and legal
                    consequences. Without someone in your corner, you can find
                    yourself losing time, money, and hope.</Typography>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Container>
            <Box align="center" my={5}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Image priority width="417" height="417" layout="intrinsic" sizes="100vw"
                    src="https://cms.eatonrealty.com/sites/default/files/images/2022/01/image%207.png"
                    alt="" className="wp-image-669604" data-entity-type="file"
                    data-entity-uuid="a1e62df6-3d5a-4b63-b676-b78419b05b62"
                    data-image-style="original"/>
                </Grid>
                <Grid item xs={12} md={6} style={{margin: 'auto'}}>
                  <Typography
                    variant="h2">MOVE <strong>CONFIDENTLY</strong></Typography>
                  <br/>
                  <Typography>Eaton Realty simplifies all Tampa Bay real estate
                    transactions by protecting your interests and expertly
                    navigating you through the challenges you’ll face.
                    Throughout, we act as your ambassador so you can move
                    confidently and with less stress. Eaton Realty empowers you,
                    making buying, selling, or property management a rewarding
                    experience and giving you more time to enjoy yourself with
                    friends and family.</Typography>
                  <CustomMuiButton color="primary" size="medium"
                                   label="Contact Us" stronglabel='Today'
                                   href="/contact-us"></CustomMuiButton>
                </Grid>
              </Grid>
            </Box>
          </Container>
          <GutenbergBody body={body_json.json}></GutenbergBody>
        </div>
        }
        {body?.length > 0 && _.isEmpty(body_json) &&
        <Typography paragraph component={`div`}>
          <div dangerouslySetInnerHTML={{__html: body}}></div>
        </Typography>
        }
      </main>
    </div>
  )
}
