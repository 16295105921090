import React from 'react';
import {parseGutenbergReact} from "../../../api/global";
import 'inclind_react/lib/GutenbergBody.css';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

const GutenbergBody = (props) => {
  const {body} = props;

  return (
    <ReactPlaceholder type='text' rows={40} ready={body}
                      showLoadingAnimation={true}>

      {body && body.length > 0 &&

      body.map((block, key) => {
        return parseGutenbergReact(key, block);
      })

      }
    </ReactPlaceholder>
  );
};

export default GutenbergBody;
